<template>
  <div class="nium-page-wrap">
    <div class="header-banner">
      <HomeNavigationBarNium :nav-data="pageData.navs" data-aos="fade-up" class="x-nav-bar" />
      <div class="header-bg" />
      <div class="banner-content">
        <div class="left">
          <div class="title-text">
            NiumCode牛码
          </div>
          <div class="sub-title">
            新一代二维码业务平台
          </div>
          <div class="title-extra-large">
            <div>
              像搭积木一样,简单拖拽即可免费生成您的动态二维码/在线表单
            </div>
          </div>
          <n-button
            tag="a"
            :href="pageData.x.buttonLink"
            class="button"
            ghost
            color="#47B41B"
            target="_blank"
          >
            免费使用
          </n-button>
        </div>
        <div class="right">
          <div class="right-img">
            <img class="banner-img" src="../public/images/nium-banner.png">
          </div>
        </div>
      </div>
      <div class="floor-content">
        <div class="floor-content-container">
          <div class="floor-content-top">
            <div v-for="item in pageData.footer.footerList" :key="item" class="floor-content-box">
              <h3>{{ item.title }}</h3>
              <div class="floor-box">
                <n-button
                  v-for="index in item.linkTextList"
                  :key="index"
                  text
                  tag="a"
                  :href="index.link"
                  target="_blank"
                  type="primary"
                  class="floor-box-button"
                >
                  {{ index.label }}
                </n-button>
              </div>
            </div>
            <div class="floor-content-box">
              <img class="floor-content-box-img" :src="pageData.footer.images">
            </div>
          </div>
          <hr class="floor-hr">
          <div class="floor-content-bottom" v-html="pageData.footer.location" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { NIcon } from 'naive-ui'
import {
  PersonCircleOutline as UserIcon,
  Pencil as EditIcon,
  LogOutOutline as LogoutIcon
} from '@vicons/ionicons5'

const renderIcon = (icon: Component) => {
  return () => {
    return h(NIcon, null, {
      default: () => h(icon)
    })
  }
}
useHead({
  title: '牛码二维码',
  meta: [
    { name: 'description', content: '免费二维码业务平台' }
  ],
  link: [
    { rel: 'icon', href: '/nium.ico', sizes: 'any' },
    { rel: 'stylesheet', href: '//at.alicdn.com/t/c/font_4348154_m5w0r2ywl.css' }
  ]
})
useSeoMeta({
  title: '牛码二维码',
  ogTitle: 'nium二维码',
  description: '二维码生成器,表单二维码,二维码制作,设备码制作,二维码,二维码图片,二维码生成,二维码扫描,二维码美化,批量生成二维码,一物一码,微信二维码',
  ogDescription: '牛码二维码（nium）新一代二维码业务平台，简单拖拽即可生成业务二维码，进行数据收集、内容展示、核销预约、数据统计、批量生成一物一码。'
})

const pageData = {
  x: {
    buttonLink: 'https://cl.nium.top',
    videoUrl: 'https://www.bilibili.com/video/BV16c411D77b/?share_source=copy_web&vd_source=1697cba9dbdc63cdd1e1d35bc59a2eb0'
  },
  navs: {
    logoImg: 'https://oss.tduckcloud.com/nium-gr-logo.png',
    logoLink: '/nium',
    menus: [
      {
        label: '介绍',
        link: '#',
        blank: true // false 不跳转
      }, {
        label: '产品',
        options: [
          {
            label: 'TDuck - 社区版',
            key: 'marina bay sands',
            props: {
              onClick: () => {
                window.open('https://gitee.com/TDuckApp/tduck-platform')
              }
            }
            // icon: renderIcon(UserIcon)
            // disabled: true
          },
          {
            label: 'TDuckPro-Vue2版',
            props: {
              onClick: () => {
                window.open('/pro')
              }
            }
          },
          {
            label: 'TDuckX-Vue3版',
            props: {
              onClick: () => {
                window.open('/x')
              }
            }
          },
          {
            label: 'TReport - 数据大屏',
            props: {
              onClick: () => {
                window.open('https://gitee.com/TDuckApp/tduck-report-platform')
              }
            }
          },
          {
            label: 'nium - 牛码二维码',
            props: {
              onClick: () => {
                window.open('/nium')
              }
            }
          }
        ]
      },
      // {
      //   label: '交付方案',
      //   linkType: 2,
      //   blank: false
      // },
      {
        label: '文档中心',
        link: '#',
        blank: true // false 不跳转
      },
      // {
      //   label: '新推官',
      //   link: 'https://x.tduckcloud.com/s/KaKq8SCn',
      //   blank: true
      // },
      {
        label: '咨询',
        link: 'https://pro.tduckcloud.com/s/QOt4xFOP',
        blank: true // false 不跳转
      }
      // {
      //   label: 'TDuck Pro',
      //   link: '/',
      //   blank: false
      // },
      // {
      //   label: '向团队介绍',
      //   link: 'https://minio.tduckcloud.com/landing/TDuckX.pdf',
      //   blank: true // false 不跳转
      // }
    ]
  },
  footer: {
    logoUrl: 'https://minio.tduckcloud.com/landing/bb326f9678cb4874b0a5e53b186ded0d.png',
    description: '',
    footerList: [
      {
        title: '产品列表',
        linkTextList: [{
          label: '社区开源版',
          link: 'https://gitee.com/TDuckApp'
        },
        {
          label: 'TDuckPro-Vue2版',
          link: 'https://doc.tduckcloud.com/buyPro/'
        },
        {
          label: 'TDuckX-Vue3版',
          link: 'https://www.tduckcloud.com/x'
        }, {
          label: 'TReport数据大屏',
          link: 'https://gitee.com/TDuckApp/tduck-report-platform'
        },
        {
          label: 'nium牛码二维码',
          link: 'https://nium.top'
        }
        ]
      },
      {
        title: '关于我们',
        linkTextList: [{
          label: '公益组织支持计划',
          link: 'https://x.tduckcloud.com/s/7wzfTiuG'
        }, {
          label: 'Tduck-Life',
          link: 'https://space.bilibili.com/409825300'
        },
        {
          label: '使用条款',
          link: 'https://doc.tduckcloud.com/blog/userAgreement.html'
        },
        {
          label: '免责声明',
          link: 'https://doc.tduckcloud.com/buyPro/disclaimer.html'
        }
        ]
      },
      {
        title: '联系我们',
        linkTextList: [{
          label: '咨询作者',
          link: 'https://pro.tduckcloud.com/s/eOJT6IEA'
        },
        {
          label: 'mama@tduckapp.com',
          link: '#'
        },
        {
          label: '文档中心',
          link: 'https://doc.tduckcloud.com/'
        }
        ]
      },
      {
        title: '友情链接',
        linkTextList: [{
          label: 'Pig微服务',
          link: 'https://www.pig4cloud.com/'
        },
        {
          label: '交换友链',
          link: 'https://x.tduckcloud.com/s/3c1CjVYX'
        }

        ]
      }
    ],
    images: 'https://minio.tduckcloud.com/landing/images/serviceQrCode.png',
    location: 'Copyright © 2019 - 2024 TDuckCloud. <a target="_blank" style="color: #1890ff" href="https://beian.miit.gov.cn">湘ICP备19005498号-6 </a>&nbsp;- 湖南省众达数蔚信息技术有限公司- &nbsp; All Rights Reserved.填鸭表单版权所有'
  }
}
</script>

<style lang="scss" scoped>

@media screen and (max-width: 1067px) {

  .nium-page-wrap .banner-content {
    flex-direction: column;
  }
  .nium-page-wrap .banner-content .title-text {
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    text-align: center;
    color: #333;
  }
  .nium-page-wrap .banner-content .sub-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 76px;
    margin-top: 8px;
    color: #47B41B;
  }
  .nium-page-wrap .banner-content .left {
    align-items: center;
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .nium-page-wrap .banner-content .button {
    /* 按钮 6 */
    animation: blink 2s infinite; /* 1s表示动画的持续时间，infinite表示无限循环 */
    margin-top: 50px;
    width: 200px;
    height: 43px;
    border-radius: 18px;
    box-sizing: border-box;
    border: 1px solid #47B41B;
    box-shadow: 0px 0px 12px 2px #47B41B;
    background-color: transparent;
    color: #1f1f1f;
    font-size: 14px;
    z-index: 999; /* 设置一个较高的层级 */
    text-align: center;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }

  .nium-page-wrap .banner-content .right {
    margin-bottom: 50px;
    .right-img {
      width: 100%;
      margin-left: 0px;
    }
    .banner-img{
      width: 400px;
    }
  }

  /*底部*/
  .floor-content-top {
    padding: 30px 0 !important;
  }
  .floor-content-logo {
    width: 100% !important;
    align-items: center !important;

    img {
      width: 120px !important;
    }
  }

  .floor-content-box {
    width: 100% !important;
    padding: 20px!important;
    h3 {
      text-align: left;
      margin-top: 30px;
    }
  }

  .floor-content-bottom {
    font-size: 10px;
    padding: 20px 0;
    text-align: center;
  }

  .floor-box {
    margin-top: 5px !important;
    .floor-box-button{
      font-size: small;
      margin-top: 10px;
    }
  }

  .floor-content-box-img{
    width: 30% !important;
    margin-top: 20px;
  }
  .floor-content-bottom {
    font-size: 10px !important;
  }

  .title-extra-large {
    /*辅助文字*/
    margin-top: 12px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 2px;
    text-align: center;
    padding: 0px 20px;
    color: #666;
  }
}

.nium-page-wrap {
  width: 100%;
  height: 100vh;
}

.header-banner {
  height: 800px;
}

.header-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../public/images/nium-bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-content {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  justify-content: center;
}

.x-nav-bar {
  width: 100vw !important;
  z-index: 1 !important;
}

.left {
  width: 32%;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right {
  .right-img {
    width: 600px;
  }

  .banner-img{
    width: 800px;
    margin-left: -100px;
  }

}

.title-text {
  font-size: 50px;
  font-weight: 600;
  line-height: 76px;
  color: #202328;
}

.sub-title {
  font-size: 50px;
  font-weight: 600;
  line-height: 76px;
  margin-top: 8px;
  color: #47B41B;
}

.title-extra-large {
  /* 私有部署 × 数据安全 */
  margin-top: 12px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 2px;
  color: #666;
}

.button {
  /* 按钮 6 */
  animation: blink 0.8s infinite; /* 1s表示动画的持续时间，infinite表示无限循环 */
  margin-top: 20px;
  width: 209px;
  height: 63px;
  border-radius: 38px;
  box-sizing: border-box;
  border: 1px solid #47B41B;
  box-shadow: 0px 0px 12px 2px #47B41B;
  background-color: transparent;
  z-index: 999; /* 设置一个较高的层级 */
  font-size: 16px;
  font-weight: 900;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  min-width: 0;
  color: #47B41B;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/*底部*/
.floor-content {
  position: relative;
  background-color: #ffffff;
  min-height: 360px;
  color: #999;

  .floor-content-container {
    margin: 0 auto;
    max-width: 1200px;
    height: 100%;
  }

  .floor-content-top {
    display: flex;
    flex-wrap: wrap;
    padding: 60px 30px 80px;
  }

  .floor-content-box {
    padding: 0px;
    width: 220px;

    h3 {
      color: #696969;
    }

    img {
      width: 90%;
    }
  }

  .floor-content-logo {
    width: 215px;
    display: flex;
    flex-direction: column;

    img {
      width: 50%;
    }

    span {
      letter-spacing: 1.5px;
      font-size: 12px;
    }
  }

  .floor-box {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    align-items: flex-start;

    .floor-box-button {
      color: #999;
      margin-bottom: 20px;
      font-size: 14px;
    }
  }

  .floor-content-bottom {
    padding-bottom: 50px;
    text-align: center;
    font-size: 12px;
  }

  .floor-content-box .floor-content-box-img{
    width: 50%;
    margin-top: 0px;
  }

  .floor-hr{
    border-top: 1px solid hsla(0, 0%, 91.4%, .1);
    margin-bottom: 15px;
  }
}
</style>
