<template>
  <div class="nav-wrapper">
    <div class="nav-inline-wrapper">
      <div class="left-wrap">
        <div class="header-logo">
          <a :href="navData?.logoLink">
            <img :src="navData?.logoImg">
          </a>
          <div class="container" :class="{ change: isChanged }" @click="toggleMenu">
            <hamburger-button
              theme="outline"
              size="24"
              fill="#fff"
              class="icon"
              :class="[!isChanged?'':'hidden']"
            />
            <close-small
              theme="outline"
              size="24"
              fill="#fff"
              class="icon"
              :class="[isChanged?'':'hidden']"
            />
          </div>
        </div>
        <div class="header-menu">
          <div class="header-menu-box" :class="[isChanged?'show-menu':'hidden-menu']">
            <n-button
              v-for="item in navData.menus"
              :key="item"
              class="header-menu-content"
              text
              tag="a"
              :href="getMenuLink(item)"
              :target="item.blank ? '_blank' : null"
              type="info"
            >
              <n-dropdown style="border-radius: 10px;margin-top: 10px;" trigger="hover" :options="item.options">
                {{ item.label }}
              </n-dropdown>
              <n-badge v-if="item.new" value="新" color="red" />
            </n-button>
          </div>
        </div>
      </div>
      <div class="header-menu-button ">
        <n-button
          style="border-radius:9px"
          tag="a"
          size="small"
          :href="docPageData.nium.buttonLink"
          ghost
          color="#47B41B"
          target="_blank"
        >
          免费使用
        </n-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" name="NavigationBar">
import { ref, computed } from 'vue'
import { HamburgerButton, CloseSmall } from '@icon-park/vue-next'
import type { NavsType } from '~/types/home/navs'

const isChanged = ref(false)
const toggleMenu = () => {
  isChanged.value = !isChanged.value
}

const props = defineProps<{
  navData: NavsType
}>()
const getMenuLink = (item: any) => {
  if (item.linkType === 2) {
    return '#pricing-section'
  } else {
    return item.link
  }
}
const navData = computed(() => props.navData)
const docPageData = {
  nium: { buttonLink: 'https://cl.nium.top' }
}
</script>
<style scoped lang="scss">
@media (max-width: 767px) {

  .header-menu-button{
    display: none !important;
  }
  .nav-inline-wrapper {
    padding: 15px 0 !important;
    flex-direction: column;
    max-width: 100% !important;
    align-items: flex-start !important;
  }

  .header-logo {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    width: 100vw;
  }
  .hidden-menu {
    display: none !important;
  }

  .container {
    display: none !important;
  }
  .header-menu-box {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  .header-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #C4EEDB;
  }
  .header-menu-content {
    margin: 8px;
  }
  .header-menu-content:hover {
    color: #fff !important;
    border: none !important;
  }
}

/*pc样式*/
.header-menu-button{
  float: right;
  margin-right: 20px;
}

.container {
  display: none;
  cursor: pointer;
}

.show-menu {
  display: flex;
}

.header-menu-content {
  float: left;
  display: block;
  color: #262626 !important;
  text-align: center;
  padding: 6px 19px;
  text-decoration: none;
  font-size: 14px;
  border-radius: 10px;
}

.header-menu-content:hover {
  color: #ffffff !important;
  background-color: #47B41B;
  border-radius: 8px;
}

.icon {
  display: flex;
  position: relative;
  transition: transform .3s cubic-bezier(.645, .045, .355, 1), opacity .3s cubic-bezier(.645, .045, .355, 1);;
}

.hidden {
  position: absolute;
  top: -200;
  visibility: hidden;
  pointer-events: none; /* 隐藏时不响应点击事件 */
}

.nav-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.nav-inline-wrapper {
  height: 65px;
  width: 60%;
  margin: auto;
  will-change: transform;
  color: #b3c0d1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .header-logo {
    img {
      width: 100px;
    }

    margin-right: 60px;
  }
}

.left-wrap{
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
}

</style>
